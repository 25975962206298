import { ready } from "#js/components/utils"
import * as Sentry from "#dependencies/@sentry/browser"

const config = Object.assign(
  globalThis.sentryConfig || {},
  {
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.captureConsoleIntegration({ levels: ["error"] }),
      Sentry.replayIntegration(),
    ],
  },
)

ready(() => {
  if (config.dsn) {
    Sentry.init({
      ...config,
    })
    globalThis.Sentry = Sentry
  }
})
